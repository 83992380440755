<template>
  <div v-show="!isHidden" :style="computedStyle" :class="CSSClasses">
    <el-radio-group
      v-if="type === 'basic' || type === 'button'"
      @change="$emit('input', localValue)"
      :disabled="_isReadonly"
      :name="name"
      :style="this.CSS"
      :size="size"
      v-model="localValue"
    >
      <component
        v-for="(item, index) in _options"
        :key="index"
        :label="item.value"
        :is="type === 'basic' ? 'el-radio' : 'el-radio-button'"
      >
        {{ item.name }}
      </component>
    </el-radio-group>

    <el-radio-group v-else-if="type === 'lights'" :disabled="_isReadonly" :name="name" :style="this.CSS + ';width:100%'">
      <template v-for="(item, index) in _options">
        <el-tooltip
          v-if="item.tooltip"
          class="item"
          effect="dark"
          :key="index"
          :content="item.tooltip"
        >
          <el-button
            circle
            size="mini"
            :style="{background: item.color}"
            @click="localValue = item.value, $emit('input', item.value)"
            :label="item.value"
          ></el-button>
        </el-tooltip>

        <el-button
          v-else
          circle
          size="mini"
          :style="{background: item.color}"
          :key="index"
          @click="localValue = item.value, $emit('input', item.value)"
          :label="item.value"
        ></el-button>
      </template>
    </el-radio-group>

    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import legacyMixin from '@/components/InterfaceEditor/components/legacyMixin'
import { cloneObject, jsonParse } from '@/helpers'

export default {
  name: 'a-radio',

  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },

  mixins: [mixin, legacyMixin, VisibleMixin],

  props: {
    value: {
      frozen: true
    },

    model: {
      frozen: true
    },

    editorAlias: {
      type: String,
      description: 'alias'
    },

    defaultValue: {
      type: String,
      description: 'default',
      options: {
        tooltip: {
          show: true,
          content: 'default_name'
        }
      }
    },

    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },

    width: {
      type: String,
      description: 'width'
    },

    block: {
      type: Boolean,
      description: 'full_line',
      default: true
    },

    type: {
      type: String,
      description: 'view',
      default: 'basic',
      editor: 'Select',
      options: {
        options: [
          { id: 'basic', name: 'Стандарт' },
          { id: 'lights', name: 'Светофор' },
          { id: 'button', name: 'Кнопки' }
        ]
      }
    },

    size: {
      type: String,
      description: 'size',
      editor: 'Size'
    },

    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    },

    options: {
      type: Array,
      default () {
        return [{ name: 'Лэйбл', value: 'Значение' }]
      },
      editor: 'Options'
    }
  },

  data () {
    return {
      localValue: this.value ?? this.defaultValue
    }
  },

  computed: {
    lights () {
      for (const element of this.options) {
        if (element.color) {
          return true
        }
      }

      return false
    },

    _options () {
      const result = cloneObject(this.options)

      if (!Array.isArray(result)) {
        return this.options
      }

      return result.map(element => {
        if (element.value === '{{user_id}}') {
          element.value = this.$store.getters['Authorization/userId']
        } else if (element.value === '{{role_id}}') {
          element.value = this.$store.getters['Authorization/roleId']
        } else if (element.value === '') {
          element.value = null
        } else {
          element.value = jsonParse(element.value)
        }

        return element
      })
    },

    computedStyle () {
      const style = {}

      if (!this.block) {
        style.display = 'inline-block'

        if (this.width) {
          style.width = this.width
        }
      }

      return style
    }
  },

  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },

    value () {
      if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
        this.localValue = this.value

        this.parseValue()
      }
    },

    localValue () {
      if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
        this.$emit('input', this.localValue)
      }
    }
  },

  created () {
    this.parseValue()
  },

  methods: {
    parseValue () {
      const parsed = jsonParse(this.localValue ?? this.value)

      if (Array.isArray(parsed) && parsed.length > 0) {
        this.localValue = parsed[0].id
      } else if (parsed === '{{user_id}}') {
        this.localValue = this.$store.getters['Authorization/userId']
      } else if (parsed === '{{role_id}}') {
        this.localValue = this.$store.getters['Authorization/roleId']
      } else {
        this.localValue = parsed
      }
    }
  }
}
</script>
